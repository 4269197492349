import { db } from '@/config/firebase';

async function getAssetTypes() {
    let assetTypesObj = {};

    const dbRef = db.collection('assetTypes');
    const querySnapshots = await dbRef.get();

    querySnapshots.forEach(doc => {
        const id = doc.id;
        let assetTypeObj = doc.data();
        assetTypesObj[id] = {
            id: id,
            ...assetTypeObj
        }
    });

    return assetTypesObj;
}

async function getAssetTypeById(assetTypeId) {
    const dbRef = db.collection('assetTypes').doc(assetTypeId);
    const querySnapshot = await dbRef.get();

    let assetType = {};
    assetType = {
        id: querySnapshot.id,
        ...querySnapshot.data()
    }

    return assetType
}

async function getAssetTypeByName(name) {
    let assetTypeObj = {};
    const query = db.collection('assetTypes').where("name", "==", name).limit(1);
    const querySnapshot = await query.get();

    querySnapshot.forEach((doc) => {
        assetTypeObj = doc.data();
    });

    return assetTypeObj;
}

export default {
    getAssetTypes,
    getAssetTypeById,
    getAssetTypeByName
}