<template>
	<b-modal id="edit-asset-type" size="lg" title="Edit Asset Type" ref="modal" ok-title="Update" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<div>
					<b-tabs content-class="mt-3">
						<b-tab title="Primary Information" active>
							<b-row class="my-2">
								<b-col sm="8">
									<b>PRIMARY INFORMATION</b>
								</b-col>
							</b-row>

							<b-row class="my-3">
								<b-col lg="6" md="12" sm="12">
									<b-form-group label="Origin" label-for="origin">
										<b-form-input name="Origin" type="text" v-model="form.origin" maxlength="25"
											readonly />
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="my-3">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Asset Code Tagging" label-for="assetTagging" description="">
										<b>{{ form.assetTagging }}</b>
									</b-form-group>
								</b-col>

								<b-col lg="6" md="6" sm="12" v-if="form.assetTagging === 'Default'">
									<b-form-group label="Category" label-for="Category">
										<b-form-input name="Category" type="text" v-model="form.type" maxlength="25"
											readonly />
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="my-3">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Name" label-for="name" :description="
										isDefault
											? 'This is a predetermined value based on the selected category and origin'
											: 'Allowed characters are numbers, letters, and some special characters: :, -, (, ), \\, /, and ,'
									">
										<b-form-textarea name="Name" type="text" v-model="form.name" maxlength="500"
											:rows="4" readonly />
									</b-form-group>
								</b-col>

								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Description" label-for="description" description>
										<b-form-textarea name="Description" type="text" v-model="form.description"
											maxlength="200" :rows="4" placeholder="Description" />
										<span v-show="errors.has('Description')" class="help-block">{{
											errors.first('Description')
										}}</span>
									</b-form-group>
								</b-col>

								<b-col sm="6">
									<b-form-group label="Number of Stickers" label-for="description" description>
										<b-form-input name="Number of Stickers" type="number" v-model="form.noOfStickers"
											min="1" max="10" v-validate="'required|numeric|min_value:1|max_value:10'" />
										<span v-show="errors.has('Number of Stickers')" class="help-block">{{
											errors.first('Number of Stickers')
										}}</span>
									</b-form-group>
								</b-col>

								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Unit of Measure (UOM)" label-for="uom">
										<b-form-input name="uom" type="text" v-model="form.uom" maxlength="25" readonly />
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Asset Type Codes">
							<div v-show="form.assetTagging === 'Default'">
								<b-row class="my-2">
									<b-col sm="8">
										<b>ASSET TYPE CODES</b>
									</b-col>
								</b-row>

								<b-row class="mb-1" no-gutters>
									<b-col sm="5">
										<b-form-group label-cols-sm="2" label="Prefix" label-for="Prefix"
											description="ALLOWED VALUE: Up to 4 capital letters." inline>
											<b-form-input name="Prefix" type="text" v-model="prefix" maxlength="4"
												v-validate="{
													regex: /^([A-Z]{1,4})$/,
												}" />
											<span v-show="errors.has('Prefix')" class="help-block">{{
												errors.first('Prefix')
											}}</span>
										</b-form-group>
									</b-col>
									<b-col sm="1 ml-1">
										<b-button variant="success" @click="onAddAssetTypeCode">Add</b-button>
									</b-col>
									<b-col sm="12" class="my-1">
										<b-table show-empty striped hover :items="form.assetTypeCodes" :fields="fields">
											<template v-slot:cell(action)="row">
												<b-button v-if="!row.item.id || row.item.id.length === 0" size="sm"
													v-b-tooltip.hover.top="'Remove'" variant="danger"
													@click="onRemoveAssetTypeCode(row.item)" class="mr-1">
													<i class="fa fa-trash-o"></i>
												</b-button>
												<span v-else><i>Can't remove an existing code</i></span>
											</template>
										</b-table>
									</b-col>
								</b-row>
							</div>

							<div v-show="form.assetTagging === 'Custom'">
								<b-row class="my-2">
									<b-col sm="8">
										<b>DEFINE ASSET TAGGING</b>
									</b-col>
								</b-row>

								<b-row class="mb-1" no-gutters>
									<b-col sm="12" class="my-1">
										<b-form-group label="Regular Expression" label-for="Regular Expression"
											description="Make sure your regular expression covers all tagging specifications of your asset code">
											<b-form-textarea name="Regular Expression" type="text" v-model="form.regex"
												v-validate="{
													required: !isDefault,
												}" maxlength="500" placeholder="Enter regular expression here..." rows="3" max-rows="6" />
											<span v-show="errors.has('Regular Expression')" class="help-block">
												{{ errors.first('Regular Expression') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="my-2">
									<b-col sm="8"> Test Values </b-col>
								</b-row>

								<b-row no-gutters>
									<b-col sm="6" class="mr-4">
										<b-form-group :description="'Test Value 1'">
											<b-form-input :name="'Test Value 1'" type="text" v-model="form.testValues[0]"
												v-validate="{
													required: !isDefault,
													regex: regex,
												}" placeholder="Type Here" maxlength="50" />
											<span v-show="errors.has('Test Value 1')" class="help-block">
												{{ errors.first('Test Value 1') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col sm="2" class="mt-1" v-if="regex && regex.test(form.testValues[0])">
										<span class="lbl-passed">PASSED</span>
									</b-col>
									<b-col sm="2" class="mt-1" v-else>
										<span class="lbl-failed">FAILED</span>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col sm="6" class="mr-4">
										<b-form-group :description="'Test Value 2'">
											<b-form-input :name="'Test Value 2'" type="text" v-model="form.testValues[1]"
												v-validate="{
													required: !isDefault,
													regex: regex,
												}" placeholder="Type Here" maxlength="50" />
											<span v-show="errors.has('Test Value 2')" class="help-block">
												{{ errors.first('Test Value 2') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col sm="2" class="mt-1" v-if="regex && regex.test(form.testValues[1])">
										<span class="lbl-passed">PASSED</span>
									</b-col>
									<b-col sm="2" class="mt-1" v-else>
										<span class="lbl-failed">FAILED</span>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col sm="6" class="mr-4">
										<b-form-group :description="'Test Value 3'">
											<b-form-input :name="'Test Value 3'" type="text" v-model="form.testValues[2]"
												v-validate="{
													required: !isDefault,
													regex: regex,
												}" placeholder="Type Here" maxlength="50" />
											<span v-show="errors.has('Test Value 3')" class="help-block">
												{{ errors.first('Test Value 3') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col sm="2" class="mt-1" v-if="regex && regex.test(form.testValues[2])">
										<span class="lbl-passed">PASSED</span>
									</b-col>
									<b-col sm="2" class="mt-1" v-else>
										<span class="lbl-failed">FAILED</span>
									</b-col>
								</b-row>
							</div>
						</b-tab>
					</b-tabs>
				</div>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { ValidationUtil } from '@/utils/validationUtil';

// API & Database
import api from '@/api/assetTypeApi';
import AssetTypeCodesDAO from '@/database/assetTypeCodes';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'edit-asset-type',
	components: {
		Loading,
	},
	data() {
		return {
			// Asset Type Codes
			prefix: '',
			fields: [
				{
					key: 'codeName',
					label: 'Series Name',
					sortable: true,
				},
				{
					key: 'prefix',
				},
				'action',
			],

			form: {
				id: '',
				name: '',
				origin: '',
				originId: '',
				brand: '',
				type: null,
				description: '',
				noOfStickers: 1,
				uom: '',
				isActive: 'true',
				assetTypeCodes: [],
				assetTagging: 'Default',
				regex: '',
				testValues: ['', '', ''],
				dateCreated: '',
				createdBy: '',
				dateUpdated: '',
				updatedBy: '',
			},

			assetCategoryOptions: [],
			selCompany: config.companyDefaultValue,
			loggedUser: this.$store.getters.loggedUser,
			assetTaggingOptions: config.assetTaggingOptions,

			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		selCompany: function () {
			if (this.selCompany.id) {
				this.updateAssetTypeName();
			}
		},
		'form.assetTagging': function () {
			if (this.form.assetTagging === 'Custom') {
				// reset form type
				this.form.type = null;
			}
			this.updateAssetTypeName();
		},
	},
	computed: {
		isDefault() {
			return this.form.assetTagging === 'Default';
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.form.name;
		},
		regex() {
			try {
				return new RegExp(this.form.regex);
			} catch (error) {
				return null;
			}
		},
	},
	mounted() {
		this.retrieveData();
	},
	methods: {
		retrieveData() {
			this.assetCategoryOptions.push({
				value: null,
				text: ' - Please select - ',
			});

			let assetTypeCategories = this.$store.getters.assetTypeCategories;
			let assetCategoryOptionsArr = Object.values(assetTypeCategories);
			assetCategoryOptionsArr.forEach((option) => {
				this.assetCategoryOptions.push({
					value: option.id,
					text: option.name,
				});
			});
		},
		async onAddAssetTypeCode() {
			if (this.prefix.length <= 0) {
				this.$toaster.warning('Prefix is required.');
				return;
			}

			let existsAssetTypeCode = await this.hasExistingAssetTypeCode();
			if (existsAssetTypeCode) {
				this.$toaster.warning(
					'Asset type code already exists on this asset type or other asset type(s).'
				);
				return;
			}

			if (!/^([A-Z]{1,4})$/.test(this.prefix)) {
				this.$toaster.warning(
					'Invalid prefix format. Up to 4 capital letters only.'
				);
				return;
			}

			let assetTypeCode = {
				prefix: this.prefix,
				codeName: this.getCodeName(this.prefix),
			};
			this.form.assetTypeCodes.push(assetTypeCode);
			this.prefix = '';
		},
		onRemoveAssetTypeCode(assetTypeCode) {
			let index = _.findIndex(this.form.assetTypeCodes, function (item) {
				return item.prefix == assetTypeCode.prefix;
			});
			if (index >= 0) {
				this.form.assetTypeCodes = _.filter(
					this.form.assetTypeCodes,
					function (item) {
						return item.prefix !== assetTypeCode.prefix;
					}
				);
			}
		},

		async hasExistingAssetTypeCode() {
			const uniqueValues = {
				prefix: this.prefix,
			};

			let exists = _.some(
				Object.values(this.form.assetTypeCodes),
				uniqueValues
			);
			if (exists) {
				return exists;
			} else {
				let assetTypeCodeObj = await AssetTypeCodesDAO.getAssetTypeCodeByPrefix(
					this.prefix
				);
				return !_.isEmpty(assetTypeCodeObj);
			}
		},

		updateAssetTypeName() {
			if (this.form.assetTagging === 'Default') {
				let brand = '';
				if (this.selCompany.brand) {
					brand = this.selCompany.brand;
				}
				let type = '';
				if (this.form.type) {
					type = this.form.type;
				}
				this.form.name = brand + ' ' + type;
			}
		},
		getCodeName(prefix) {
			if (prefix && prefix.length > 0) {
				return prefix + '-Series';
			}
			return '';
		},
		updateCompanySelection() {
			this.form.origin = this.selCompany.name;
			this.form.originId = this.selCompany.id;
			this.form.brand = this.selCompany.brand;
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();
			// assign the selected company to the user
			this.updateCompanySelection();
			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			if (
				this.form.assetTagging === 'Default' &&
				this.form.assetTypeCodes.length === 0
			) {
				this.$toaster.warning('At least 1 asset type code is required.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			if (this.form.assetTagging === 'Custom') {
				let assetTypeName = this.form.name.trim();
				if (!assetTypeName.startsWith(this.selCompany.brand)) {
					this.$toaster.warning(
						"Asset type name must start with the origin's brand name: " +
						this.selCompany.brand
					);
					// hide loading indicator
					this.isLoading = false;
					return;
				} else if (assetTypeName === this.selCompany.brand) {
					this.$toaster.warning('Please complete your asset type name');
					// hide loading indicator
					this.isLoading = false;
					return;
				}

				try {
					new RegExp(this.form.regex);
				} catch (error) {
					this.$toaster.warning('Invalid regular expression.');
					// hide loading indicator
					this.isLoading = false;
					return;
				}

				let testValues = this.form.testValues;
				if (
					!this.regex.test(testValues[0]) &&
					!this.regex.test(testValues[1]) &&
					!this.regex.test(testValues[2])
				) {
					this.$toaster.warning(
						'All test values must pass the regular expression.'
					);
					// hide loading indicator
					this.isLoading = false;
					return;
				}

				let uniqCount = _.uniq(this.form.testValues).length;
				if (uniqCount < 3) {
					this.$toaster.warning('All test values must be unique.');
					// hide loading indicator
					this.isLoading = false;
					return;
				}
			}

			this.handleSubmit();
		},

		processFormFields() {
			// Removes excess whitespace
			this.form.description = ValidationUtil.removeExcessWhiteSpace(
				this.form.description
			);
			this.form.noOfStickers = parseInt(this.form.noOfStickers);

			if (this.form.assetTagging === 'Custom') {
				let brand = this.selCompany.brand;
				let assetTypeName = this.form.name;
				this.form.type = assetTypeName.substring(brand.length).trim();
			}
		},
		getParam() {
			this.processFormFields();

			return {
				currUserId: this.loggedUser.id,
				assetType: this.form,
			};
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let { data } = await api.saveAssetType(this.getParam());

				// hide loading indicator
				this.isLoading = false;

				if (data.isSuccess) {
					this.$toaster.success(
						`Asset Type "${this.name}" was updated successfully.`
					);
					EventBus.$emit('onCloseEditAssetType', {
						assetType: data.assetType,
						assetTypeCodes: data.assetTypeCodes,
					});
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(
						`Error updating asset type "${this.name}". Please try again.`
					);
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;

				this.$toaster.error(
					`Error updating asset type "${this.name}". Please try again.`
				);
			}
		},

		async onReset() {
			// show loading indicator
			this.isLoading = true;

			let assetTypeId = this.$store.getters.currAssetType.id;
			let param = {
				currUserId: this.loggedUser.id,
				assetTypeId: assetTypeId,
			};
			const { data } = await api.getAssetType(param);
			let assetType = data.assetType;
			assetType.assetTypeCodes = data.assetTypeCodes;

			let assetTypeJSON = JSON.stringify(assetType);
			this.form = JSON.parse(assetTypeJSON);

			// update other fields
			this.form.noOfStickers =
				typeof assetType.noOfStickers === 'undefined'
					? 1
					: assetType.noOfStickers;
			this.form.uom = assetType.uom ? assetType.uom : '';
			this.form.assetTagging = assetType.assetTagging
				? assetType.assetTagging
				: 'Default';

			if (this.form.assetTagging === 'Custom') {
				this.form.regex = assetType.regex ? assetType.regex : '';
				this.form.testValues = assetType.testValues ? assetType.testValues : [];
			}

			// init company
			this.selCompany = {
				id: assetType.originId,
				name: assetType.origin,
				brand: assetType.brand,
			};

			// reset validation
			this.$validator.reset();
			this.errors.clear();

			// hide loading indicator
			this.isLoading = false;
		},
	},
};
</script>

<style scoped>
.lbl-passed {
	font-weight: bold !important;
	color: #28a745 !important;
}

.lbl-failed {
	font-weight: bold !important;
	color: #dc3545 !important;
}
</style>


