<template>
	<div>
		<b-card no-body>
			<b-tabs card>
				<b-tab title="Primary Information" active>
					<b-form-group
						label="Name:"
						label-cols-sm="3"
						label-class="font-weight-bold pt-0"
						label-align-sm="right"
					>
					<span :class="{ truncate : row.item.name.length > 50 }">
						{{ row.item.name }}
					</span>
					</b-form-group>

					<b-form-group
						label="Description:"
						label-cols-sm="3"
						label-class="font-weight-bold pt-0"
						label-align-sm="right"
					>
						<span style="width: 500px; word-wrap: break-word !important">{{
							showValue(row.item.description)
						}}</span>
					</b-form-group>

					<b-form-group
						label="Category:"
						label-cols-sm="3"
						label-class="font-weight-bold pt-0"
						label-align-sm="right"
					>
						<span :class="{ truncate : row.item.type.length > 50 }">
							{{ showValue(row.item.type) }}
						</span>
					</b-form-group>

					<b-form-group
						label="Asset Tagging:"
						label-cols-sm="3"
						label-class="font-weight-bold pt-0"
						label-align-sm="right"
					>
						{{ row.item.assetTagging ? row.item.assetTagging : 'Default' }}
					</b-form-group>

					<b-form-group
						label="Asset Type Owner:"
						label-cols-sm="3"
						label-class="font-weight-bold pt-0"
						label-align-sm="right"
					>
						{{ row.item.origin }}
					</b-form-group>

					<b-form-group
						label="No of Stickers:"
						label-cols-sm="3"
						label-class="font-weight-bold pt-0"
						label-align-sm="right"
					>
						{{ row.item.noOfStickers }}
					</b-form-group>

					<b-form-group
						label="Unit of Measure (UOM):"
						label-cols-sm="3"
						label-class="font-weight-bold pt-0"
						label-align-sm="right"
					>
						{{ row.item.uom }}
					</b-form-group>

					<b-form-group
						label="Date Created:"
						label-cols-sm="3"
						label-class="font-weight-bold pt-0"
						label-align-sm="right"
					>
						{{ getFormattedDateWithTime(row.item.dateCreated) }}
					</b-form-group>

					<b-form-group
						label="Date Updated:"
						label-cols-sm="3"
						label-class="font-weight-bold pt-0"
						label-align-sm="right"
					>
						{{ getFormattedDateWithTime(row.item.dateUpdated) }}
					</b-form-group>
				</b-tab>
				<b-tab
					title="Asset Type Codes"
					v-if="row.item.assetTagging !== 'Custom'"
				>
					<loading
						:active.sync="isLoading"
						loader="spinner"
						color="#20A8D8"
						:is-full-page="false"
					/>
					<b-col sm="6">
						<b-col sm="12" class="my-1">
							<b-table
								show-empty
								striped
								hover
								:items="assetTypeCodes"
								:fields="assetTypeCodesFields"
							>
							</b-table>
						</b-col>
					</b-col>
					<b-col sm="6" v-show="getAssetTypePoolCount(row.item.id) > 0">
						<b-col sm="12" class="md-left-text-sm-right">
							<b>ASSET TYPE DISTRIBUTION</b>
						</b-col>
						<b-col sm="12" class="my-1">
							<b-table
								show-empty
								striped
								hover
								:items="assetTypePools"
								:fields="assetTypePoolsFields"
							>
							</b-table>
						</b-col>
					</b-col>
				</b-tab>
				<b-tab title="Asset Type Codes" v-else>
					<b-form-group
						label="Regular Expression:"
						label-cols-sm="3"
						label-class="font-weight-bold pt-0"
						label-align-sm="right"
					>
						/{{ row.item.regex }}/
					</b-form-group>

					<b-form-group
						label="Test Values:"
						label-cols-sm="3"
						label-class="font-weight-bold pt-0"
						label-align-sm="right"
					>
						<b-badge pill variant="success">{{
							row.item.testValues[0]
						}}</b-badge>
						&nbsp;
						<b-badge pill variant="success">{{
							row.item.testValues[1]
						}}</b-badge>
						&nbsp;
						<b-badge pill variant="success">{{
							row.item.testValues[2]
						}}</b-badge>
						&nbsp;
					</b-form-group>
				</b-tab>
			</b-tabs>
		</b-card>
	</div>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { SortUtil } from '@/utils/sortUtil';

// API
import api from '@/api/assetTypeApi';

// Others
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'asset-type-details-view',
	components: {
		Loading,
	},
	props: {
		row: {
			type: Object,
			required: true,
		},
		assetTypeDistribution: {
			type: Object,
			required: true,
		},
		assetLocationDistribution: {
			type: Object,
			required: true,
		},
		storageLocationsObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			assetTypeCodesFields: [
				{
					key: 'codeName',
					label: 'Series Name',
				},
				{
					key: 'prefix',
				},
			],
			assetTypeCodes: [],

			assetTypePoolsFields: [
				{
					key: 'company',
				},
				{
					key: 'storageLocation',
				},
				{
					key: 'count',
					label: 'Total',
				},
			],
			assetTypePools: [],

			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
	async mounted() {
		// Show loading indicator
		this.isLoading = true;

		let assetTypeId = this.row.item.id;
		this.assetTypePools = this.getAssetTypePool(assetTypeId);

		let param = {
			currUserId: this.loggedUser.id,
			assetTypeId: assetTypeId,
		};
		let { data } = await api.getAssetType(param);
		this.assetTypeCodes = data.assetTypeCodes;

		// Hide loading indicator
		this.isLoading = false;
	},
	methods: {
		showValue(value) {
			if (!_.isEmpty(value)) {
				return value;
			}
			return '-';
		},
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		getAssetTypePoolCount(assetTypeId) {
			let totalCount = 0;
			if (this.assetTypeDistribution[assetTypeId]) {
				totalCount = this.assetTypeDistribution[assetTypeId].totalQuantity;
			}
			return totalCount;
		},
		getAssetTypePool(assetTypeId) {
			let vm = this;

			let companyDistribution = [];

			if (this.assetLocationDistribution) {
				let locIds = Object.keys(this.assetLocationDistribution);

				locIds.forEach((locId) => {
					let location = vm.storageLocationsObj[locId];
					let distribution = vm.assetLocationDistribution[locId];
					if (
						distribution.assetTypes[assetTypeId] &&
						distribution.assetTypes[assetTypeId].groupCount > 0
					) {
						companyDistribution.push({
							locId: locId,
							company: location.company,
							storageLocation: location.name,
							count: distribution.assetTypes[assetTypeId].groupCount,
						});
					}
				});
			}

			companyDistribution = SortUtil.sortByAttr('company', companyDistribution);
			return companyDistribution;
		},
	},
};
</script>

<style scoped>
.truncate {
	width: 300px; 
	display: inline-block;
}
</style>
