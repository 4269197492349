<template>
	<div class="animated fadeIn">
		<b-card title="Asset Type" sub-title="Manages the asset type ownership for each company">
			<b-container class="mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="mt-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toogle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>
					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2">
							<b-card>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Company">
											<v-select class="style-chooser" label="text" :options="companyOptions"
												:reduce="(company) => company.value" v-model="filterBy.company">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Tagging">
											<v-select class="style-chooser" label="text" :options="assetTaggingOptions"
												:reduce="(tagging) => tagging.value" v-model="filterBy.assetTagging">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for tagging option
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Status">
											<v-select class="style-chooser" label="text" :options="statusOptions"
												:reduce="(isActive) => isActive.value" v-model="filterBy.isActive">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for status
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Search
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mt-4 mb-2">
						<b-dropdown text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item v-b-modal.add-asset-type v-show="!isViewer">
								Add Asset Type
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="xls" :name="fileName + '.xls'">
									Export Asset Types in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="csv" :name="fileName + '.csv'">
									Export Asset Types to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
					:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
					:sort-direction="sortDirection" responsive>

					<template v-slot:cell(name)="row">
						<span :class="{ truncate: row.item.name.length > 50 }">
							{{ row.item.name }}
						</span>
					</template>

					<template v-slot:cell(actions)="row">
						<span class="text-nowrap">
							<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="success"
								@click.stop="row.toggleDetails" class="mr-1">
								<i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
								<i class="fa fa-eye" v-else></i>
							</b-button>
							<b-button size="sm" v-b-modal.edit-asset-type v-b-tooltip.hover.top="'Edit Details'"
								variant="dark" @click.stop="updateSelAssetType(row.item)" class="mr-1"
								v-show="!isViewer && isAssetTypeOwner(row.item)">
								<i class="fa fa-pencil"></i>
							</b-button>
						</span>
					</template>

					<template slot="row-details" slot-scope="row">
						<AssetTypeDetailsView :row="row" :assetTypeDistribution="assetTypeDistribution"
							:assetLocationDistribution="assetLocationDistribution"
							:storageLocationsObj="storageLocationsObj" />
					</template>
				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="totalDisplay">Total: {{ totalRows }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
			</b-container>
		</b-card>

		<!-- Modals here -->
		<AddAssetType :companyFilter="filterBy.company" />
		<EditAssetType />
	</div>
</template>

<script>
// Component
import AssetTypeDetailsView from '@/views/asset/assetType/AssetTypeDetailsView';
import AddAssetType from './assetType/AddAssetType';
import EditAssetType from './assetType/EditAssetType';

// Util
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { DateUtil } from '@/utils/dateutil';

// API
import assetTypeApi from '@/api/assetTypeApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';

export default {
	name: 'asset-type-management',
	components: {
		AssetTypeDetailsView,
		AddAssetType,
		EditAssetType,
		Loading,
		JsonExcel,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'name',
					label: 'Name',
					sortable: true,
				},
				{
					key: 'origin',
					label: 'Asset Type Owner',
				},
				{
					key: 'uom',
					label: 'Unit of Measure (UOM)',
				},
				'actions',
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			companyOptions: [],
			assetTaggingOptions: DropDownItemsUtil.retriveAssetTaggingItems(),
			statusOptions: config.statusOptionsWithDefault,

			allCompaniesObj: {},
			allAssetTypesObj: {},
			allAssetTypeCodesObj: {},

			selAssetType: {},

			assetTypeDistribution: {},
			assetLocationDistribution: {},
			storageLocationsObj: {},

			defaultFilterBy: {
				company: Object.assign({}, config.companyDefaultValue),
				assetTagging: '',
				isActive: 'true',
			},
			filterBy: {
				company: Object.assign({}, config.companyDefaultValue),
				assetTagging: '',
				isActive: 'true',
			},
			prevFilter: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			return {
				Name: 'name',
				Description: 'description',
				Category: 'type',
				'Asset Tagging': 'assetTagging',
				'Asset Type Owner': 'origin',
				'No of Stickers': 'noOfStickers',
				'Unit of Measure (UOM)': 'uom',
				'Regular Expresssion': 'regex',
				'Test Values': 'Test Values',
				'Date Created': 'Date Created',
				'Date Updated': 'Date Updated',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return (
				'AssetTypes-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp)
			);
		},
	},
	mounted() {
		setTimeout(() => {
			try {
				// Don't initiate data retrieval when the account is not authenticated
				if (!this.$store.getters.isAuthenticated) {
					return;
				}

				// show loading indicator
				this.isLoading = true;

				// Load initial data
				this.allCompaniesObj = this.$store.getters.companies;
				this.storageLocationsObj = this.$store.getters.storageLocations;
				this.allAssetTypesObj = this.$store.getters.assetTypes;
				this.allAssetTypeCodesObj = this.$store.getters.assetTypeCodes;

				this.companyOptions = DropDownItemsUtil.retrieveCompanyAssetOwnerItems(
					this.allCompaniesObj
				);

				if (!this.isSuperAdmin) {
					this.filterBy.company = DropDownItemsUtil.getCompanyItem(
						this.loggedUserCompany
					);
					this.defaultFilterBy.company = DropDownItemsUtil.getCompanyItem(
						this.loggedUserCompany
					);
				}

				this.filterTableContent();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		}, config.timeout);

		// Event Listeners
		EventBus.$on('onCloseAddAssetType', (data) => {
			let assetTypeObj = data.assetType;
			if (!_.isEmpty(assetTypeObj)) {
				this.updateTable(assetTypeObj);
			}

			let assetTypeCodesObj = data.assetTypeCodes;
			if (!_.isEmpty(assetTypeCodesObj)) {
				this.$store.dispatch('updateAllAssetTypeCodes', assetTypeCodesObj);
			}
		});

		EventBus.$on('onCloseEditAssetType', (data) => {
			let assetTypeObj = data.assetType;
			if (!_.isEmpty(assetTypeObj)) {
				this.updateTable(assetTypeObj);
			}

			let assetTypeCodesObj = data.assetTypeCodes;
			if (!_.isEmpty(assetTypeCodesObj)) {
				this.$store.dispatch('updateAllAssetTypeCodes', assetTypeCodesObj);
			}
		});
	},
	methods: {
		updateTable(assetTypeObj) {
			if (!_.isEmpty(assetTypeObj)) {
				this.allAssetTypesObj[assetTypeObj.id] = assetTypeObj;
				this.$store.dispatch('setAllAssetTypes', this.allAssetTypesObj);

				this.filterTableContent();
			}
		},
		filterTableContent() {
			let filteredObj = Object.assign({}, this.allAssetTypesObj);

			_.forEach(this.allAssetTypesObj, (assetType, assetTypeId) => {
				if (
					this.filterBy.company.id !== null &&
					this.filterBy.company.id !== assetType.originId
				) {
					delete filteredObj[assetTypeId];
				}

				let assetTagging = this.filterBy.assetTagging;
				if (
					!_.isEmpty(assetTagging) &&
					assetTagging !== assetType.assetTagging
				) {
					delete filteredObj[assetTypeId];
				}

				if (this.filterBy.isActive !== assetType.isActive) {
					delete filteredObj[assetTypeId];
				}
			});

			this.items = Object.values(filteredObj);
			this.items.forEach((item) => {
				item['Test Values'] = _.join(item.testValues, ',');
				item['Date Created'] = this.getFormattedDateWithTime(item.dateCreated);
				item['Date Updated'] = this.getFormattedDateWithTime(item.dateUpdated);
			});

			this.items = _.sortBy(this.items, ['name']);
			this.totalRows = this.items.length;
		},

		async retrieveData() {
			try {
				// Show loader
				this.isLoading = true;

				let filter = Object.assign({}, this.filterBy);
				if (this.isSuperAdmin) {
					filter.withParentCompany = true;
					filter.companyId = this.loggedUserCompany.id;
					const { data } = await assetTypeApi.getAssetTypes(
						filter,
						config.view.ADMIN,
						this.loggedUser.id
					);

					this.allAssetTypesObj = _.assign(
						this.allAssetTypesObj,
						data.assetTypes
					);
					this.allAssetTypeCodesObj = _.assign(
						this.allAssetTypeCodesObj,
						data.assetTypeCodes
					);
				} else {
					filter.withParentCompany = true;
					filter.companyId = this.loggedUserCompany.id;
					const { data } = await assetTypeApi.getAssetTypes(
						filter,
						config.view.COMPANY,
						this.loggedUser.id
					);

					this.allAssetTypesObj = _.assign(
						this.allAssetTypesObj,
						data.assetTypes
					);
					this.allAssetTypeCodesObj = _.assign(
						this.allAssetTypeCodesObj,
						data.assetTypeCodes
					);
				}

				this.filterTableContent();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		onFilterRequest() {
			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				this.retrieveData();
				this.prevFilter = Object.assign({}, this.filterBy);
			}
		},
		resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = Object.assign({}, this.defaultFilterBy);
				this.retrieveData();
				this.prevFilter = Object.assign({}, this.filterBy);
			}
		},
		updateSelAssetType(item) {
			this.selAssetType = item;
			this.$store.commit('SET_CURR_ASSET_TYPE', item);
			EventBus.$emit('onUpdateSelAssetType', item);
		},

		isAssetTypeOwner(assetType) {
			return (
				assetType.originId === this.loggedUserCompany.id ||
				this.loggedUser.type === 'Super Admin'
			);
		},
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	},
	beforeDestroy() {
		EventBus.$off('onCloseAddAssetType');
		EventBus.$off('onCloseEditAssetType');
	},
};
</script>

<style scoped>
.truncate {
	width: 300px;
	display: inline-block;
}
</style>
